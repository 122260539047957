const { __ } = wp.i18n;
const statusField = document.querySelector( '.realpress-search-field.status' );

const getStatusValue = () => {
	if ( ! statusField ) {
		return '';
	}

	if ( statusField.classList.contains( 'status-select' ) ) {
		const selectNode = statusField.querySelector( 'select[name=status]' );
		const value = selectNode.tomselect.getValue();
		if ( value === '0' ) {
			return '';
		}
		return selectNode.tomselect.getValue();
	}
	if ( statusField.classList.contains( 'status-input' ) ) {
		const radioNode = statusField.querySelector( 'input:checked' );
		if ( radioNode.value === 'all' ) {
			let termId = [];
			const inputNodes = statusField.querySelectorAll( '.item input' );
			[ ...inputNodes ].map( ( inputNode ) => {
				termId = [ ...termId, inputNode.value ];
			} );
			return termId.toString();
		}
		return radioNode.value;
	}

	return '';
};

const resetStatus = () => {
	if ( ! statusField ) {
		return '';
	}

	if ( statusField.classList.contains( 'status-input' ) ) {
		const radioNodes = statusField.querySelectorAll( 'input' );
		[ ...radioNodes ].map( ( radioNode ) => {
			if ( radioNode.value === 'all' ) {
				radioNode.checked = true;
			} else {
				radioNode.checked = false;
			}
		} );
	}

	if ( statusField.classList.contains( 'status-select' ) ) {
		const selectNode = statusField.querySelector( 'select[name=status]' );
		selectNode.tomselect.setValue( 'all' );
	}
};

export { getStatusValue, resetStatus };
