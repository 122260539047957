import realpressTomSelect from '../mixins/tom-select';
import realpressToggleWishList from './wishlist/toggle-wishlist';
import realpressToggleCompare from './compare-property/toggle-compare';
import realpressMedia from '../mixins/media';
import realpressAdvancedSearch from './shortcode/advanced-search/advanced-search';
import realpressAgentSearch from './widgets/agent-search';

document.addEventListener( 'DOMContentLoaded', ( event ) => {
	realpressTomSelect();
	realpressToggleWishList();
	realpressToggleCompare();
	realpressMedia();
	realpressAdvancedSearch();
	realpressAgentSearch();
} );
