const numberDecimals = REALPRESS_GLOBAL_OBJECT.number_decimals || 0;
const currencySymbol = REALPRESS_GLOBAL_OBJECT.currency_symbol || '';
const thousandsSeparator = REALPRESS_GLOBAL_OBJECT.thousands_separator || '';
const currencyPosition = REALPRESS_GLOBAL_OBJECT.currency_position || 'left';
const renderPrice = ( price ) => {
	price = renderPriceNumber( price );
	switch ( currencyPosition ) {
	case 'left':
		price = currencySymbol + price;
		break;
	case 'right':
		price = price + currencySymbol;
		break;
	case 'left_space':
		price = currencySymbol + ' ' + price;
		break;
	case 'right_space':
		price = price + ' ' + currencySymbol;
		break;
	default:
		break;
	}

	return price;
};

const renderPriceNumber = ( price ) => {
	price = ( price / 1 ).toFixed( numberDecimals );
	price = price.toString().replace( /\B(?=(\d{3})+(?!\d))/g, thousandsSeparator );

	return price;
};

export { renderPrice, renderPriceNumber };
