import { addQueryArgs } from '@wordpress/url';
import { getQuery } from '../advanced-search';

const { __ } = wp.i18n;
const restUrl = REALPRESS_GLOBAL_OBJECT.rest_url || '';
const restNamespace = REALPRESS_GLOBAL_OBJECT.rest_namespace || '';

const keywordFieldNode = document.querySelector( '.realpress-search-field.keyword' );
let keywordSearchNode, suggestNode, suggestResult, suggestData, suggestTimeoutId;
const realpressKeyword = () => {
	if ( ! keywordFieldNode ) {
		return;
	}

	handleEnterEvent();
	searchSuggestion();
};

const handleEnterEvent = () => {
	document.addEventListener( 'keyup', function( event ) {
		const target = event.target;

		if ( target.name !== 'keyword' ) {
			return;
		}

		const advancedSearch = target.closest( '.realpress-advanced-search' );

		if ( ! advancedSearch ) {
			return;
		}

		const searchBtn = advancedSearch.querySelector( '.realpress-search' );
		if ( ! searchBtn ) {
			return;
		}

		if ( event.keyCode === 13 && target.value ) {
			searchBtn.click();
		}
	} );
};

const searchSuggestion = () => {
	keywordSearchNode = keywordFieldNode.querySelector( '.realpress-search-keyword' );

	if ( ! keywordSearchNode ) {
		return;
	}
	suggestNode = keywordSearchNode.querySelector( 'input[name="keyword"][data-suggest="on"]' );
	if ( ! suggestNode ) {
		return;
	}

	suggestResult = keywordFieldNode.querySelector( '.realpress-suggest-result' );
	suggestNode.addEventListener( 'input', function() {
		if ( this.value.length < 3 ) {
			return;
		}

		fetchSuggestSearchData();
	} );

	suggestNode.addEventListener( 'focus', function() {
		if ( suggestData ) {
			suggestResult.innerHTML = suggestData;
			suggestResult.classList.add( 'show' );
		} else {
			fetchSuggestSearchData();
			suggestResult.classList.remove( 'show' );
		}
	} );
	document.addEventListener( 'click', function( event ) {
		if ( ! suggestNode.contains( event.target ) ) {
			suggestResult.innerHTML = '';
			suggestResult.classList.remove( 'show' );
		}
	} );
};

const getSuggestResult = ( res ) => {
	const properties = res.data.property;
	let result = '';

	for ( let i = 0; i < properties.length; i++ ) {
		const property = properties[ i ];
		result += `<li class="realpress-suggest-item"><a href="${ property.permalink }" target="_blank">${ property.title }</a></li>`;
	}

	if ( Object.hasOwn( res.data, 'next_page' ) ) {
		result += `<button type="button" class="realpress-view-all">` + __( 'View All', 'realpress' ) + `</button>`;
	}
	return result;
};

const fetchSuggestSearchData = () => {
	//Clear all calback before
	clearTimeout( suggestTimeoutId );

	const keyword = suggestNode.value;
	const spinnerIcon = keywordSearchNode.querySelector( 'i.rp-spinner' );
	if ( ! keyword ) {
		if ( spinnerIcon ) {
			keywordSearchNode.querySelector( 'i.rp-spinner' ).remove();
		}
		suggestData = '';
		suggestResult.innerHTML = '';
		suggestResult.classList.remove( 'show' );
		return;
	}
	if ( ! spinnerIcon ) {
		keywordSearchNode.insertAdjacentHTML( 'beforeend', '<i class="rp-spinner"></i>' );
	}

	let query = getQuery();
	query = { ...query, keyword, template: 'search_suggest' };
	suggestTimeoutId = setTimeout( () => {
		fetch( addQueryArgs( restUrl + restNamespace + '/properties', query ), { method: 'GET' } )
			.then( ( res ) => res.json() )
			.then( ( res ) => {
				if ( res.data.property ) {
					suggestData = getSuggestResult( res );
					suggestResult.innerHTML = suggestData;
					suggestResult.classList.add( 'show' );
				} else if ( res.msg ) {
					suggestData = '';
					suggestResult.innerHTML = '';
					suggestResult.classList.remove( 'show' );
				}
			} ).catch( ( err ) => {
				console.log( err );
			} ).finally( () => {
				const spinnerIcon = keywordSearchNode.querySelector( 'i.rp-spinner' );
				if ( spinnerIcon ) {
					keywordSearchNode.querySelector( 'i.rp-spinner' ).remove();
				}
				clearTimeout( suggestTimeoutId );
			} );
	}, 1000 );
};

const getKeywordValue = () => {
	if ( ! keywordFieldNode ) {
		return '';
	}

	const keywordNode = keywordFieldNode.querySelector( 'input[name="keyword"]' );
	if ( keywordNode && keywordNode.value ) {
		return keywordNode.value;
	}

	return '';
};

const resetKeyword = () => {
	if ( ! keywordFieldNode ) {
		return '';
	}

	keywordFieldNode.querySelector( 'input[name="keyword"]' ).value = '';
};

export { getKeywordValue, resetKeyword };
export default realpressKeyword;
