import { renderPrice } from '../../../../mixins/price';
import 'nouislider';

const { __ } = wp.i18n;
let minPrice, maxPrice, step;

const priceFieldNode = document.querySelector( '.realpress-search-field.price' );
const realpressPrice = () => {
	if ( ! priceFieldNode ) {
		return;
	}
	minPrice = priceFieldNode.getAttribute( 'data-min' );
	maxPrice = priceFieldNode.getAttribute( 'data-max' );
	step = priceFieldNode.getAttribute( 'data-step' );

	if ( minPrice === '' || maxPrice === '' || step === '' ) {
		return;
	}
	priceSlider();
	priceInput();
};

const priceSlider = () => {
	if ( ! priceFieldNode.classList.contains( 'price-slider' ) ) {
		return;
	}

	const minPriceNode = document.getElementById( 'realpress-min-price' );
	const maxPriceNode = document.getElementById( 'realpress-max-price' );
	const priceSliderNode = document.getElementById( 'realpress-search-price' );
	const start = minPriceNode.value || minPrice;
	const end = maxPriceNode.value || maxPrice;
	step = parseInt( step );
	noUiSlider.create( priceSliderNode, {
		start: [ parseInt( start ), parseInt( end ) ],
		connect: true,
		step,
		tooltips: false,
		range: {
			min: parseInt( minPrice ), max: parseInt( maxPrice ),
		},
		// direction: 'lt',
	} );

	priceSliderNode.noUiSlider.on( 'update', function( values, handle, unencoded ) {
		minPriceNode.value = parseInt( values[ 0 ] );
		maxPriceNode.value = parseInt( values[ 1 ] );
		priceFieldNode.querySelector( '.min' ).innerHTML = renderPrice( values[ 0 ] );
		priceFieldNode.querySelector( '.max' ).innerHTML = renderPrice( values[ 1 ] );
	} );
};

const priceInput = () => {
	if ( ! priceFieldNode.classList.contains( 'price-input' ) ) {
		return;
	}

	const fieldItemNodes = priceFieldNode.querySelectorAll( '.field-item' );
	[ ...fieldItemNodes ].map( ( fieldItemNode ) => {
		const displayItemNode = fieldItemNode.querySelector( '.display-item' );
		const inputNode = displayItemNode.querySelector( 'input[type="text"]' );
		const warningNode = displayItemNode.querySelector( 'span.warning' );
		const listNode = fieldItemNode.querySelector( '.list-item ul' );
		const listItems = fieldItemNode.querySelectorAll( 'li' );

		inputNode.addEventListener( 'focus', function( event ) {
			fieldItemNode.classList.add( 'active' );

			//Filter min, max value
			const isMinPriceInput = inputNode.name === 'min-price';
			if ( isMinPriceInput ) {
				const maxPriceValue = priceFieldNode.querySelector( 'input[name="max-price"]' ).value;

				if ( maxPriceValue === '0' ) {
					listNode.style.display = 'none';
				} else {
					listNode.style.display = 'block';
				}

				[ ...listItems ].map( ( listItem ) => {
					if ( parseInt( listItem.getAttribute( 'data-value' ) ) >= parseInt( maxPriceValue ) ) {
						listItem.style.display = 'none';
					} else {
						listItem.style.display = 'block';
					}
				} );
			} else {
				const minPriceValue = priceFieldNode.querySelector( 'input[name="min-price"]' ).value;
				[ ...listItems ].map( ( listItem ) => {
					if ( parseInt( listItem.getAttribute( 'data-value' ) ) <= parseInt( minPriceValue ) ) {
						listItem.style.display = 'none';
					} else {
						listItem.style.display = 'block';
					}
				} );
			}
		} );

		[ ...listItems ].map( ( listItem ) => {
			listItem.addEventListener( 'click', function( event ) {
				inputNode.value = listItem.getAttribute( 'data-value' );
			} );
		} );

		document.addEventListener( 'click', function( event ) {
			if ( ! inputNode.contains( event.target ) ) {
				fieldItemNode.classList.remove( 'active' );
			}
		} );

		inputNode.addEventListener( 'change', function() {
			const value = inputNode.value;
			if ( isNaN( value ) ) {
				inputNode.value = '';
			} else {
				inputNode.value = value;
			}
			warningNode.innerHTML = '';
		} );

		inputNode.addEventListener( 'keyup', function() {
			fieldItemNode.classList.add( 'active' );
			let value = inputNode.value;
			if ( isNaN( value ) ) {
				warningNode.innerHTML = __( 'Invalid number', 'realpress' );
			} else {
				warningNode.innerHTML = '';
				if ( value ) {
					value = +value;
				}
				inputNode.value = value;
			}
		} );
	} );
};

const getMinPriceValue = () => {
	if ( ! priceFieldNode ) {
		return '';
	}

	const minPriceNode = priceFieldNode.querySelector( 'input[name="min-price"]' );
	if ( minPriceNode && minPriceNode.value ) {
		return minPriceNode.value;
	}

	return '';
};

const getMaxPriceValue = () => {
	if ( ! priceFieldNode ) {
		return '';
	}

	const maxPriceNode = priceFieldNode.querySelector( 'input[name="max-price"]' );
	if ( maxPriceNode && maxPriceNode.value ) {
		return maxPriceNode.value;
	}

	return '';
};

const resetPrice = () => {
	if ( ! priceFieldNode ) {
		return '';
	}

	if ( priceFieldNode.classList.contains( 'price-slider' ) ) {
		const priceSliderNode = document.getElementById( 'realpress-search-price' );
		minPrice = priceFieldNode.getAttribute( 'data-min' );
		maxPrice = priceFieldNode.getAttribute( 'data-max' );

		priceSliderNode.noUiSlider.updateOptions( {
			start: [ parseInt( minPrice ), parseInt( maxPrice ) ],
		} );
	}

	if ( priceFieldNode.classList.contains( 'price-input' ) ) {
		const minPriceNode = priceFieldNode.querySelector( 'input[name="min-price"]' );
		const maxPriceNode = priceFieldNode.querySelector( 'input[name="max-price"]' );

		minPriceNode.value = '';
		maxPriceNode.value = '';
	}
};

export { getMinPriceValue, getMaxPriceValue, resetPrice };
export default realpressPrice;
