const { __ } = wp.i18n;
const locationField = document.querySelector( '.realpress-search-field.location' );
let toggleBtn, applyBtn, addClass;

const realpressLocation = () => {
	if ( ! locationField ) {
		return;
	}

	toggleBtn = locationField.querySelector( '.toggle' );
	applyBtn = locationField.querySelector( '.apply' );

	const selectAllNode = locationField.querySelector( '.all' );
	const selectAllInputNode = selectAllNode.querySelector( 'input' );
	const termNodes = locationField.querySelectorAll( '.item' );

	selectAllInputNode.addEventListener( 'change', function( event ) {
		if ( event.target.checked ) {
			toggleBtn.innerHTML = sprintf( __( 'Location (%s)', 'realpress' ), termNodes.length );
			toggleBtn.classList.add( 'has-value' );
			[ ...termNodes ].map( ( termNode ) => {
				termNode.querySelector( 'input' ).checked = true;
			} );
		} else {
			toggleBtn.innerHTML = __( 'Location', 'realpress' );
			toggleBtn.classList.remove( 'has-value' );
			[ ...termNodes ].map( ( termNode ) => {
				termNode.querySelector( 'input' ).checked = false;
			} );
		}
	} );

	let checkedAll = true;
	for ( let i = 0; i < termNodes.length; i++ ) {
		const inputNode = termNodes[ i ].querySelector( 'input' );
		if ( ! inputNode.checked ) {
			checkedAll = false;

			break;
		}
	}

	selectAllInputNode.checked = checkedAll;

	[ ...termNodes ].map( ( termNode ) => {
		const inputNode = termNode.querySelector( 'input' );

		inputNode.addEventListener( 'change', function( event ) {
			const termCheckedNodes = locationField.querySelectorAll( '.item input:checked' );
			if ( termCheckedNodes.length ) {
				toggleBtn.innerHTML = sprintf( __( 'Location (%s)', 'realpress' ), termCheckedNodes.length );
				toggleBtn.classList.add( 'has-value' );
			} else {
				toggleBtn.innerHTML = __( 'Location', 'realpress' );
				toggleBtn.classList.remove( 'has-value' );
			}
		} );
	} );

	[ ...termNodes ].map( ( termNode ) => {
		const inputNode = termNode.querySelector( 'input' );

		inputNode.addEventListener( 'change', function( event ) {
			if ( event.target.checked ) {
				let checked = true;
				for ( let i = 0; i < termNodes.length; i++ ) {
					const inputNode = termNodes[ i ].querySelector( 'input' );
					if ( ! inputNode.checked ) {
						checked = false;
						break;
					}
				}
				selectAllInputNode.checked = checked;
			} else {
				selectAllInputNode.checked = false;
			}
		} );
	} );

	toggleBtn.addEventListener( 'click', function( event ) {
		event.preventDefault();
		locationField.classList.toggle( 'active' );
	} );

	applyBtn.addEventListener( 'click', function( event ) {
		event.preventDefault();
		locationField.classList.remove( 'active' );
	} );

	document.addEventListener( 'click', function( event ) {
		const target = event.target;

		if ( ! locationField.contains( target ) ) {
			locationField.classList.remove( 'active' );
		}
	} );

	//click tom select -> remove location active status
	const tomSelectNodes = document.querySelectorAll( 'select.realpress-tom-select, select.realpress-custom-select' );
	for ( let i = 0; i < tomSelectNodes.length; i++ ) {
		const tomSelectNode = tomSelectNodes[ i ];
		tomSelectNode.tomselect.on( 'focus', function() {
			locationField.classList.remove( 'active' );
		} );

		tomSelectNode.tomselect.on( 'change', function() {
			locationField.classList.remove( 'active' );
		} );
	}
};

const getLocationValue = () => {
	if ( ! locationField ) {
		return '';
	}

	const termNodes = locationField.querySelectorAll( '.parent, .child' );
	let termId = [];
	[ ...termNodes ].map( ( termNode ) => {
		const inputNode = termNode.querySelector( 'input[type=checkbox]:checked' );
		if ( inputNode ) {
			termId = [ ...termId, inputNode.value ];
		}
	} );

	return termId.toString();
};

const resetLocation = () => {
	if ( ! locationField ) {
		return;
	}

	toggleBtn = locationField.querySelector( '.toggle' );
	toggleBtn.innerHTML = __( 'Location', 'realpress' );
	const selectAllNode = locationField.querySelector( '.all' );
	const selectAllInputNode = selectAllNode.querySelector( 'input' );
	selectAllInputNode.checked = false;
	const termNodes = locationField.querySelectorAll( '.item' );
	[ ...termNodes ].map( ( termNode ) => {
		termNode.querySelector( 'input' ).checked = false;
	} );
};

export { getLocationValue, resetLocation };
export default realpressLocation;
