const { __ } = wp.i18n;
const bedField = document.querySelector( '.realpress-search-field.bed' );
let selectItemNodes, exactMatchNode, bedInput, toggleBtn, applyBtn;
const realpressBed = () => {
	if ( ! bedField ) {
		return;
	}

	bedInput = bedField.querySelector( 'input[name="bed"]' );
	toggleBtn = bedField.querySelector( '.toggle' );
	applyBtn = bedField.querySelector( '.apply' );
	selectItemNodes = bedField.querySelectorAll( 'li' );
	exactMatchNode = bedField.querySelector( '#bed-exact-match' );

	document.addEventListener( 'click', function( event ) {
		const target = event.target;

		if ( ! bedField.contains( target ) ) {
			bedField.classList.remove( 'active' );
		}

		[ ...selectItemNodes ].map( ( item ) => {
			if ( item.contains( target ) ) {
				[ ...selectItemNodes ].map( ( el ) => {
					if ( el === item ) {
						const value = el.innerHTML.trim();
						el.classList.add( 'active' );
						toggleBtn.classList.add( 'has-value' );
						if ( el.getAttribute( 'data-value' ) === '0' ) {
							bedInput.value = 'any';
							toggleBtn.innerHTML = __( 'Beds', 'realpress' );
							toggleBtn.classList.remove( 'has-value' );
						} else {
							bedInput.value = value;
							toggleBtn.innerHTML = value;
							toggleBtn.classList.add( 'has-value' );
						}
					} else {
						el.classList.remove( 'active' );
					}
				} );
			}
		} );
	} );

	//click tom select -> remove bed active status
	const tomSelectNodes = document.querySelectorAll( 'select.realpress-tom-select, select.realpress-custom-select' );
	for ( let i = 0; i < tomSelectNodes.length; i++ ) {
		const tomSelectNode = tomSelectNodes[ i ];
		tomSelectNode.tomselect.on( 'focus', function() {
			bedField.classList.remove( 'active' );
		} );

		tomSelectNode.tomselect.on( 'change', function() {
			bedField.classList.remove( 'active' );
		} );
	}

	exactMatchNode.addEventListener( 'change', function( event ) {
		if ( exactMatchNode.checked ) {
			if ( bedInput.value !== 'any' ) {
				const value = bedInput.value.replace( '+', '' );
				bedInput.value = value;
				toggleBtn.innerHTML = value;
				toggleBtn.classList.add( 'has-value' );
			}
			[ ...selectItemNodes ].map( ( item ) => {
				if ( item.getAttribute( 'data-value' ) !== '0' ) {
					item.innerHTML = item.getAttribute( 'data-value' );
				}
			} );
		} else {
			if ( bedInput.value !== 'any' ) {
				const value = bedInput.value + '+';
				bedInput.value = value;
				toggleBtn.innerHTML = value;
				toggleBtn.classList.add( 'has-value' );
			}

			[ ...selectItemNodes ].map( ( item ) => {
				if ( item.getAttribute( 'data-value' ) !== '0' ) {
					item.innerHTML = item.getAttribute( 'data-value' ) + '+';
				}
			} );
		}
	} );

	toggleBtn.addEventListener( 'click', function( event ) {
		event.preventDefault();
		bedField.classList.toggle( 'active' );
	} );

	applyBtn.addEventListener( 'click', function( event ) {
		event.preventDefault();
		bedField.classList.remove( 'active' );
	} );
};

const getBedValue = () => {
	if ( ! bedField ) {
		return;
	}

	const bedInput = bedField.querySelector( 'input[name="bed"]' );

	if ( bedInput && bedInput.value ) {
		if ( bedInput.value === 'any' ) {
			return '';
		}
		return bedInput.value;
	}

	return '';
};

const resetBed = () => {
	if ( ! bedField ) {
		return;
	}

	bedInput = bedField.querySelector( 'input[name="bed"]' );
	toggleBtn = bedField.querySelector( '.toggle' );
	bedInput.value = 'any';
	toggleBtn.innerHTML = __( 'Beds', 'realpress' );

	[ ...selectItemNodes ].map( ( item ) => {
		if ( item.getAttribute( 'data-value' ) === '0' ) {
			item.classList.add( 'active' );
		} else {
			item.classList.remove( 'active' );
			item.innerHTML = item.getAttribute( 'data-value' );
			exactMatchNode.checked = true;
		}
	} );
};

export { getBedValue, resetBed };

export default realpressBed;
