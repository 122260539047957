import { setCookie, getCookie } from '../../mixins/cookie';

const messagePopup = document.querySelector( '#realpress-snackbar' );
const restNamespace = REALPRESS_GLOBAL_OBJECT.rest_namespace || '';
const userId = REALPRESS_GLOBAL_OBJECT.user_id || '';
const { __ } = wp.i18n;
let popupTimeout;

const realpressToggleWishList = () => {
	const wishlistNode = document.querySelector( '#realpress-wishlist' );
	document.addEventListener( 'click', function( event ) {
		//If is wishlist page, return
		if ( wishlistNode && wishlistNode.contains( event.target ) ) {
			return;
		}
		const wishListNodes = document.querySelectorAll( '.realpress-property-wishlist' );
		for ( let i = 0; i < wishListNodes.length; i++ ) {
			const wishListNode = wishListNodes[ i ];
			if ( wishListNode.contains( event.target ) ) {
				let propertyId = wishListNode.getAttribute( 'data-property-id' );
				propertyId = parseInt( propertyId );
				const similarNodes = document.querySelectorAll( `.realpress-property-wishlist[data-property-id="${ propertyId }"]` );
				wishListNode.disabled = true;
				if ( userId ) {
					const data = { property_id: propertyId };
					wp.apiFetch( {
						path: '/' + restNamespace + '/wishlist', method: 'PUT', data,
					} ).then( ( res ) => {
						[ ...similarNodes ].map( ( wishlistNode ) => {
							if ( res.data.status === 'added' ) {
								wishlistNode.classList.add( 'active' );
							} else {
								wishlistNode.classList.remove( 'active' );
							}
						} );

						if ( res.data.status === 'added' ) {
							messagePopup.innerHTML = __( 'Property WishList added', 'realpress' );
						} else {
							messagePopup.innerHTML = __( 'Property WishList removed', 'realpress' );
						}
						displayMessagePopup();
					} ).catch( ( err ) => {
						console.log( err );
					} ).finally( () => {
						wishListNode.disabled = false;
					} );
				} else {
					let propertyIds = getWishListCookie();
					if ( ! propertyIds ) {
						propertyIds = [];
					}

					[ ...similarNodes ].map( ( wishlistNode ) => {
						if ( propertyIds.includes( propertyId ) ) {
							wishlistNode.classList.remove( 'active' );
						} else {
							wishlistNode.classList.add( 'active' );
						}
					} );

					//Remove wishlist property
					if ( propertyIds.includes( propertyId ) ) {
						propertyIds = propertyIds.filter( ( item ) => item !== propertyId );
						messagePopup.innerHTML = __( 'Property WishList removed', 'realpress' );
					} else {
						propertyIds.push( propertyId );
						messagePopup.innerHTML = __( 'Property WishList added', 'realpress' );
					}

					setCookie( 'realpress_wishlist_property', propertyIds.join( ',' ), 30 );
					displayMessagePopup();
				}
			}
		}
	} );

	displayActiveWishList();
};

//Fix for cache page
const displayActiveWishList = () => {
	if ( userId ) {
		return;
	}

	const wishlist = getWishListCookie();

	if ( ! wishlist ) {
		return;
	}

	const wishlistBtnNodes = document.querySelectorAll( '.realpress-property-wishlist' );

	[ ...wishlistBtnNodes ].map( ( wishlistBtn ) => {
		const propertyId = parseInt( wishlistBtn.getAttribute( 'data-property-id' ) );

		if ( wishlist.includes( propertyId ) ) {
			wishlistBtn.classList.add( 'active' );
		} else {
			wishlistBtn.classList.remove( 'active' );
		}
	} );
};

const getWishListCookie = () => {
	const propertyIds = getCookie( 'realpress_wishlist_property' );
	if ( propertyIds === null || ! propertyIds ) {
		return [];
	}

	return propertyIds.split( ',' ).map( Number );
};

const displayMessagePopup = () => {
	messagePopup.classList.add( 'active' );

	if ( popupTimeout ) {
		clearTimeout( popupTimeout );
	}

	popupTimeout = setTimeout( () => {
		messagePopup.classList.remove( 'active' );
		messagePopup.innerHTML = '';
	}, '3000' );
};

export { getWishListCookie };
export default realpressToggleWishList;
