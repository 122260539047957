const { __ } = wp.i18n;
const featureField = document.querySelector( '.realpress-search-field.feature' );

const getFeatureValue = () => {
	if ( ! featureField ) {
		return;
	}

	const featureNode = featureField.querySelector( 'select' );
	if ( featureNode.options ) {
		let termId = [];
		const options = featureNode.options;
		[ ...options ].map( ( option ) => {
			if ( option.selected && option.value ) {
				termId = [ ...termId, option.value ];
			}
		} );

		return termId.toString();
	}

	return '';
};

const resetFeature = () => {
	if ( ! featureField ) {
		return '';
	}

	const selectNode = featureField.querySelector( 'select' );
	selectNode.tomselect.setValue( '' );
};

export { getFeatureValue, resetFeature };
