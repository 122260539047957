const { __ } = wp.i18n;
const typeField = document.querySelector( '.realpress-search-field.type' );
let toggleBtn, applyBtn, btnText;

const realpressType = () => {
	if ( ! typeField ) {
		return;
	}

	toggleBtn = typeField.querySelector( '.toggle' );
	applyBtn = typeField.querySelector( '.apply' );
	const selectAllNode = typeField.querySelector( '.all' );
	const selectAllInputNode = selectAllNode.querySelector( 'input' );
	const termNodes = typeField.querySelectorAll( '.item' );
	btnText = toggleBtn.innerHTML.replace( /\((.+?)\)/g, '' );

	selectAllInputNode.addEventListener( 'change', function( event ) {
		if ( event.target.checked ) {
			toggleBtn.innerHTML = btnText + ' (' + termNodes.length + ')';
			toggleBtn.classList.add( 'has-value' );
			[ ...termNodes ].map( ( termNode ) => {
				termNode.querySelector( 'input' ).checked = true;
			} );
		} else {
			toggleBtn.innerHTML = btnText;
			toggleBtn.classList.remove( 'has-value' );
			[ ...termNodes ].map( ( termNode ) => {
				termNode.querySelector( 'input' ).checked = false;
			} );
		}
	} );

	let checkedAll = true;
	for ( let i = 0; i < termNodes.length; i++ ) {
		const inputNode = termNodes[ i ].querySelector( 'input' );
		if ( ! inputNode.checked ) {
			checkedAll = false;

			break;
		}
	}

	selectAllInputNode.checked = checkedAll;

	[ ...termNodes ].map( ( termNode ) => {
		const inputNode = termNode.querySelector( 'input' );

		inputNode.addEventListener( 'change', function( event ) {
			const termCheckedNodes = typeField.querySelectorAll( '.item input:checked' );

			if ( termCheckedNodes.length ) {
				toggleBtn.innerHTML = btnText + ' (' + termCheckedNodes.length + ')';
				toggleBtn.classList.add( 'has-value' );
			} else {
				toggleBtn.innerHTML = btnText;
				toggleBtn.classList.remove( 'has-value' );
			}
		} );
	} );

	[ ...termNodes ].map( ( termNode ) => {
		const inputNode = termNode.querySelector( 'input' );

		inputNode.addEventListener( 'change', function( event ) {
			if ( event.target.checked ) {
				let checked = true;
				for ( let i = 0; i < termNodes.length; i++ ) {
					const inputNode = termNodes[ i ].querySelector( 'input' );
					if ( ! inputNode.checked ) {
						checked = false;
						break;
					}
				}
				selectAllInputNode.checked = checked;
			} else {
				selectAllInputNode.checked = false;
			}
		} );
	} );

	toggleBtn.addEventListener( 'click', function( event ) {
		event.preventDefault();
		typeField.classList.toggle( 'active' );
	} );

	applyBtn.addEventListener( 'click', function( event ) {
		event.preventDefault();
		typeField.classList.remove( 'active' );
	} );

	document.addEventListener( 'click', function( event ) {
		const target = event.target;

		if ( ! typeField.contains( target ) ) {
			typeField.classList.remove( 'active' );
		}
	} );

	//click tom select -> remove type active status
	const tomSelectNodes = document.querySelectorAll( 'select.realpress-tom-select, select.realpress-custom-select' );
	for ( let i = 0; i < tomSelectNodes.length; i++ ) {
		const tomSelectNode = tomSelectNodes[ i ];
		tomSelectNode.tomselect.on( 'focus', function() {
			typeField.classList.remove( 'active' );
		} );

		tomSelectNode.tomselect.on( 'change', function() {
			typeField.classList.remove( 'active' );
		} );
	}
};

const getTypeValue = () => {
	if ( ! typeField ) {
		return '';
	}

	const termNodes = typeField.querySelectorAll( '.parent, .child' );
	let termId = [];
	[ ...termNodes ].map( ( termNode ) => {
		const inputNode = termNode.querySelector( 'input[type=checkbox]:checked' );
		if ( inputNode ) {
			termId = [ ...termId, inputNode.value ];
		}
	} );

	return termId.toString();
};

const resetType = () => {
	if ( ! typeField ) {
		return;
	}

	toggleBtn = typeField.querySelector( '.toggle' );
	toggleBtn.innerHTML = btnText;
	const selectAllNode = typeField.querySelector( '.all' );
	const selectAllInputNode = selectAllNode.querySelector( 'input' );
	selectAllInputNode.checked = false;
	const termNodes = typeField.querySelectorAll( '.item' );
	[ ...termNodes ].map( ( termNode ) => {
		termNode.querySelector( 'input' ).checked = false;
	} );
};

export { getTypeValue, resetType };
export default realpressType;
