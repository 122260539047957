const { __ } = wp.i18n;
const areaSizeFieldNode = document.querySelector( '.realpress-search-field.area-input' );
const realpressAreaSize = () => {
	if ( ! areaSizeFieldNode ) {
		return;
	}

	const fieldItemNodes = areaSizeFieldNode.querySelectorAll( '.field-item' );
	[ ...fieldItemNodes ].map( ( fieldItemNode ) => {
		const displayItemNode = fieldItemNode.querySelector( '.display-item' );
		const inputNode = displayItemNode.querySelector( 'input[type="text"]' );
		const warningNode = displayItemNode.querySelector( 'span.warning' );
		const listNode = fieldItemNode.querySelector( '.list-item ul' );
		const listItems = fieldItemNode.querySelectorAll( 'li' );

		inputNode.addEventListener( 'focus', function( event ) {
			fieldItemNode.classList.add( 'active' );

			//Filter min, max value
			const isMinAreaInput = inputNode.name === 'min-area';
			if ( isMinAreaInput ) {
				const maxAreaValue = areaSizeFieldNode.querySelector( 'input[name="max-area"]' ).value;
				if ( maxAreaValue === '0' ) {
					listNode.style.display = 'none';
				} else {
					listNode.style.display = 'block';
				}

				[ ...listItems ].map( ( listItem ) => {
					if ( parseInt( listItem.getAttribute( 'data-value' ) ) >= parseInt( maxAreaValue ) ) {
						listItem.style.display = 'none';
					} else {
						listItem.style.display = 'block';
					}
				} );
			} else {
				const minAreaValue = areaSizeFieldNode.querySelector( 'input[name="min-area"]' ).value;
				[ ...listItems ].map( ( listItem ) => {
					if ( parseInt( listItem.getAttribute( 'data-value' ) ) <= parseInt( minAreaValue ) ) {
						listItem.style.display = 'none';
					} else {
						listItem.style.display = 'block';
					}
				} );
			}
		} );

		[ ...listItems ].map( ( listItem ) => {
			listItem.addEventListener( 'click', function( event ) {
				inputNode.value = listItem.getAttribute( 'data-value' );
			} );
		} );

		document.addEventListener( 'click', function( event ) {
			if ( ! inputNode.contains( event.target ) ) {
				fieldItemNode.classList.remove( 'active' );
			}
		} );

		inputNode.addEventListener( 'change', function() {
			const value = inputNode.value;
			if ( isNaN( value ) ) {
				inputNode.value = '';
			} else {
				inputNode.value = value;
			}
			warningNode.innerHTML = '';
		} );

		inputNode.addEventListener( 'keyup', function() {
			fieldItemNode.classList.add( 'active' );
			let value = inputNode.value;
			if ( isNaN( value ) ) {
				warningNode.innerHTML = __( 'Invalid number', 'realpress' );
			} else {
				warningNode.innerHTML = '';
				if ( value ) {
					value = +value;
				}
				inputNode.value = value;
			}
		} );
	} );
};

const getMinAreaValue = () => {
	if ( ! areaSizeFieldNode ) {
		return '';
	}

	const minAreaNode = areaSizeFieldNode.querySelector( 'input[name="min-area"]' );
	if ( minAreaNode && minAreaNode.value ) {
		return minAreaNode.value;
	}

	return '';
};

const getMaxAreaValue = () => {
	if ( ! areaSizeFieldNode ) {
		return '';
	}

	const maxAreaNode = areaSizeFieldNode.querySelector( 'input[name="max-area"]' );
	if ( maxAreaNode && maxAreaNode.value ) {
		return maxAreaNode.value;
	}

	return '';
};

const resetAreaSize = () => {
	if ( ! areaSizeFieldNode ) {
		return;
	}

	const minAreaNode = areaSizeFieldNode.querySelector( 'input[name="min-area"]' );
	const maxAreaNode = areaSizeFieldNode.querySelector( 'input[name="max-area"]' );
	minAreaNode.value = '';
	maxAreaNode.value = '';
};

export { getMinAreaValue, getMaxAreaValue, resetAreaSize };
export default realpressAreaSize;
