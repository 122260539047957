const { __ } = wp.i18n;
const bathField = document.querySelector( '.realpress-search-field.bath' );
let selectItemNodes, exactMatchNode, bathInput, toggleBtn, applyBtn;
const realpressBath = () => {
	if ( ! bathField ) {
		return;
	}

	bathInput = bathField.querySelector( 'input[name="bath"]' );
	toggleBtn = bathField.querySelector( '.toggle' );
	applyBtn = bathField.querySelector( '.apply' );

	selectItemNodes = bathField.querySelectorAll( 'li' );
	exactMatchNode = bathField.querySelector( '#bath-exact-match' );

	document.addEventListener( 'click', function( event ) {
		const target = event.target;

		if ( ! bathField.contains( target ) ) {
			bathField.classList.remove( 'active' );
		}
		[ ...selectItemNodes ].map( ( item ) => {
			if ( item.contains( target ) ) {
				[ ...selectItemNodes ].map( ( el ) => {
					if ( el === item ) {
						const value = el.innerHTML.trim();
						el.classList.add( 'active' );
						toggleBtn.classList.add( 'has-value' );
						if ( el.getAttribute( 'data-value' ) === '0' ) {
							bathInput.value = 'any';
							toggleBtn.innerHTML = __( 'Baths', 'realpress' );
							toggleBtn.classList.remove( 'has-value' );
						} else {
							bathInput.value = value;
							toggleBtn.innerHTML = value;
							toggleBtn.classList.add( 'has-value' );
						}
					} else {
						el.classList.remove( 'active' );
					}
				} );
			}
		} );
	} );

	//click tom select -> remove bed active status
	const tomSelectNodes = document.querySelectorAll( 'select.realpress-tom-select, select.realpress-custom-select' );
	for ( let i = 0; i < tomSelectNodes.length; i++ ) {
		const tomSelectNode = tomSelectNodes[ i ];
		tomSelectNode.tomselect.on( 'focus', function() {
			bathField.classList.remove( 'active' );
		} );

		tomSelectNode.tomselect.on( 'change', function() {
			bathField.classList.remove( 'active' );
		} );
	}

	exactMatchNode.addEventListener( 'change', function( event ) {
		if ( exactMatchNode.checked ) {
			if ( bathInput.value !== 'any' ) {
				const value = bathInput.value.replace( '+', '' );
				bathInput.value = value;
				toggleBtn.innerHTML = value;
				toggleBtn.classList.add( 'has-value' );
			}
			[ ...selectItemNodes ].map( ( item ) => {
				if ( item.getAttribute( 'data-value' ) !== '0' ) {
					item.innerHTML = item.getAttribute( 'data-value' );
				}
			} );
		} else {
			if ( bathInput.value !== 'any' ) {
				const value = bathInput.value + '+';
				bathInput.value = value;
				toggleBtn.innerHTML = value;
				toggleBtn.classList.add( 'has-value' );
			}

			[ ...selectItemNodes ].map( ( item ) => {
				if ( item.getAttribute( 'data-value' ) !== '0' ) {
					item.innerHTML = item.getAttribute( 'data-value' ) + '+';
				}
			} );
		}
	} );

	toggleBtn.addEventListener( 'click', function( event ) {
		event.preventDefault();
		bathField.classList.toggle( 'active' );
	} );

	applyBtn.addEventListener( 'click', function( event ) {
		event.preventDefault();
		bathField.classList.remove( 'active' );
	} );
};

const getBathValue = () => {
	if ( ! bathField ) {
		return;
	}

	const bathInput = bathField.querySelector( 'input[name="bath"]' );

	if ( bathInput && bathInput.value ) {
		if ( bathInput.value === 'any' ) {
			return '';
		}
		return bathInput.value;
	}

	return '';
};

const resetBath = () => {
	if ( ! bathField ) {
		return;
	}

	bathInput = bathField.querySelector( 'input[name="bath"]' );
	toggleBtn = bathField.querySelector( '.toggle' );
	bathInput.value = 'any';
	toggleBtn.innerHTML = __( 'Baths', 'realpress' );
	[ ...selectItemNodes ].map( ( item ) => {
		if ( item.getAttribute( 'data-value' ) === '0' ) {
			item.classList.add( 'active' );
		} else {
			item.classList.remove( 'active' );
			item.innerHTML = item.getAttribute( 'data-value' );
			exactMatchNode.checked = true;
		}
	} );
};

export { getBathValue, resetBath };

export default realpressBath;
