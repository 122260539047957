import { addQueryArgs } from '@wordpress/url';
import realpressKeyword, { getKeywordValue, resetKeyword } from './fields/keyword';
import realpressPrice, { getMaxPriceValue, getMinPriceValue, resetPrice } from './fields/price';
import realpressRoom, { getRoomValue, resetRoom } from './fields/room';
import realpressBath, { getBathValue, resetBath } from './fields/bath';
import realpressBed, { getBedValue, resetBed } from './fields/bed';
import realpressAreaSize, { getMaxAreaValue, getMinAreaValue, resetAreaSize } from './fields/area-size';
import realpressYearBuilt, { getMaxYearValue, getMinYearValue, resetYearBuilt } from './fields/year-built';
import realpressType, { getTypeValue, resetType } from './fields/type';
import realpressLocation, { getLocationValue, resetLocation } from './fields/location';
import { getFeatureValue, resetFeature } from './fields/feature';
import { getStatusValue, resetStatus } from './fields/status';
import { getVerifiedValue, resetVerified } from './fields/verified';

let searchNode;

const realpressAdvancedSearch = () => {
	searchNode = document.querySelector( '.realpress-advanced-search' );

	if ( ! searchNode ) {
		return;
	}

	handleFields();
	reset();
	search();
};

const search = () => {
	const halfMapNode = document.querySelector( '#realpress-search-with-map' );
	const headerMapNode = document.querySelector( '#realpress-header-map' );
	const archivePropertyNode = document.querySelector( '.realpress-archive-property' );

	if ( halfMapNode || archivePropertyNode || headerMapNode ) {
		return;
	}

	const redirectUrl = REALPRESS_GLOBAL_OBJECT.advanced_search.redirect_page_url || '';

	document.addEventListener( 'click', function( event ) {
		if ( event.target.classList.contains( 'realpress-search' ) || event.target.classList.contains( 'realpress-view-all' ) ) {
			document.location.href = addQueryArgs( redirectUrl, getQuery() );
		}
	} );
};

const getQuery = ( newQuery = {} ) => {
	//Keyword
	if ( getKeywordValue() ) {
		newQuery = { ...newQuery, keyword: getKeywordValue() };
	} else if ( newQuery.hasOwnProperty( 'keyword' ) ) {
		delete newQuery.keyword;
	}

	//Price
	if ( getMinPriceValue() ) {
		newQuery = { ...newQuery, min_price: getMinPriceValue() };
	} else if ( newQuery.hasOwnProperty( 'min_price' ) ) {
		delete newQuery.min_price;
	}

	if ( getMaxPriceValue() ) {
		newQuery = { ...newQuery, max_price: getMaxPriceValue() };
	} else if ( newQuery.hasOwnProperty( 'max_price' ) ) {
		delete newQuery.max_price;
	}

	//Rooms
	if ( getRoomValue() ) {
		newQuery = { ...newQuery, room: getRoomValue() };
	} else if ( newQuery.hasOwnProperty( 'room' ) ) {
		delete newQuery.room;
	}
	//Bath
	if ( getBathValue() ) {
		newQuery = { ...newQuery, bath: getBathValue() };
	} else if ( newQuery.hasOwnProperty( 'bath' ) ) {
		delete newQuery.bath;
	}
	//Bed
	if ( getBedValue() ) {
		newQuery = { ...newQuery, bed: getBedValue() };
	} else if ( newQuery.hasOwnProperty( 'bed' ) ) {
		delete newQuery.bed;
	}
	//Area
	if ( getMinAreaValue() ) {
		newQuery = { ...newQuery, min_area: getMinAreaValue() };
	} else if ( newQuery.hasOwnProperty( 'min_area' ) ) {
		delete newQuery.min_area;
	}
	if ( getMaxAreaValue() ) {
		newQuery = { ...newQuery, max_area: getMaxAreaValue() };
	} else if ( newQuery.hasOwnProperty( 'max_area' ) ) {
		delete newQuery.max_area;
	}
	//Year Built
	if ( getMinYearValue() ) {
		newQuery = { ...newQuery, min_year: getMinYearValue() };
	} else if ( newQuery.hasOwnProperty( 'min_year' ) ) {
		delete newQuery.min_year;
	}
	if ( getMaxYearValue() ) {
		newQuery = { ...newQuery, max_year: getMaxYearValue() };
	} else if ( newQuery.hasOwnProperty( 'max_year' ) ) {
		delete newQuery.max_year;
	}

	if ( getTypeValue() ) {
		newQuery = { ...newQuery, type: getTypeValue() };
	} else if ( newQuery.hasOwnProperty( 'type' ) ) {
		delete newQuery.type;
	}

	if ( getLocationValue() ) {
		newQuery = { ...newQuery, location: getLocationValue() };
	} else if ( newQuery.hasOwnProperty( 'location' ) ) {
		delete newQuery.location;
	}

	if ( getFeatureValue() ) {
		newQuery = { ...newQuery, feature: getFeatureValue() };
	} else if ( newQuery.hasOwnProperty( 'feature' ) ) {
		delete newQuery.feature;
	}

	if ( getStatusValue() ) {
		newQuery = { ...newQuery, status: getStatusValue() };
	} else if ( newQuery.hasOwnProperty( 'status' ) ) {
		delete newQuery.status;
	}

	if ( getVerifiedValue() ) {
		newQuery = { ...newQuery, verified: getVerifiedValue() };
	} else if ( newQuery.hasOwnProperty( 'verified' ) ) {
		delete newQuery.status;
	}

	return newQuery;
};

const reset = () => {
	const resetButtonNode = document.querySelector( '.realpress-reset' );
	if ( resetButtonNode ) {
		resetButtonNode.addEventListener( 'click', function( event ) {
			event.preventDefault();
			resetKeyword();
			resetBath();
			resetBed();
			resetRoom();
			resetPrice();
			resetAreaSize();
			resetYearBuilt();
			resetType();
			resetLocation();
			resetStatus();
			resetFeature();
			resetVerified();
		} );
	}
};

const handleFields = () => {
	realpressKeyword();
	realpressBath();
	realpressBed();
	realpressRoom();
	realpressPrice();
	realpressAreaSize();
	realpressYearBuilt();
	realpressType();
	realpressLocation();
};

export { getQuery };
export default realpressAdvancedSearch;
