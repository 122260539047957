export const setCookie = ( name, value, expiredDay ) => {
	const date = new Date();
	date.setTime( date.getTime() + ( expiredDay * 24 * 60 * 60 * 1000 ) );
	const expires = 'expires=' + date.toUTCString();
	document.cookie = name + '=' + value + ';' + expires + ';path=/';
};

export const getCookie = ( name ) => {
	name += '=';
	const decodedCookie = decodeURIComponent( document.cookie );
	const ca = decodedCookie.split( ';' );
	for ( let i = 0; i < ca.length; i++ ) {
		let c = ca[ i ];
		while ( c.charAt( 0 ) === ' ' ) {
			c = c.substring( 1 );
		}
		if ( c.indexOf( name ) === 0 ) {
			return c.substring( name.length, c.length );
		}
	}

	return '';
};

export const deleteCookie = ( name ) => {
	const date = new Date();
	date.setTime( date.getTime() - 9999 );
	const expires = 'expires=' + date.toUTCString();
	document.cookie = name + '=' + value + ';' + expires + ';path=/';
};
