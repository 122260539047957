import TomSelect from 'tom-select';

const realpressTomSelect = () => {
	const tomSelectNodes = document.querySelectorAll( 'select.realpress-tom-select' );
	for ( let i = 0; i < tomSelectNodes.length; i++ ) {
		const tomSelectNode = tomSelectNodes[ i ];
		let settings = {
			maxOptions: null,
		};
		if ( tomSelectNode.multiple ) {
			const plugins = [ 'no_backspace_delete', 'remove_button', 'clear_button', 'change_listener' ];
			settings = { ...settings, plugins };
		} else {
			const plugins = [ 'clear_button' ];
			settings = { ...settings, plugins };
		}

		new TomSelect( tomSelectNode, settings );
	}

	const customSelectNodes = document.querySelectorAll( 'select.realpress-custom-select, .custom-select select' );
	for ( let i = 0; i < customSelectNodes.length; i++ ) {
		new TomSelect( customSelectNodes[ i ], {
			plugins: [ 'no_backspace_delete' ], render: {
				no_results: null,
			},
		} );
	}
};

export default realpressTomSelect;
