import TomSelect from 'tom-select';

const { __ } = wp.i18n;
const roomField = document.querySelector( '.realpress-search-field.room' );
let selectItemNodes, exactMatchNode, roomInput, toggleBtn, applyBtn;
const realpressRoom = () => {
	if ( ! roomField ) {
		return;
	}

	roomInput = roomField.querySelector( 'input[name="room"]' );
	toggleBtn = roomField.querySelector( '.toggle' );
	applyBtn = roomField.querySelector( '.apply' );

	selectItemNodes = roomField.querySelectorAll( 'li' );
	exactMatchNode = roomField.querySelector( '#room-exact-match' );

	document.addEventListener( 'click', function( event ) {
		const target = event.target;

		if ( ! roomField.contains( target ) ) {
			roomField.classList.remove( 'active' );
		}

		[ ...selectItemNodes ].map( ( item ) => {
			if ( item.contains( target ) ) {
				[ ...selectItemNodes ].map( ( el ) => {
					if ( el === item ) {
						const value = el.innerHTML.trim();
						el.classList.add( 'active' );
						toggleBtn.classList.add( 'has-value' );
						if ( el.getAttribute( 'data-value' ) === '0' ) {
							roomInput.value = 'any';
							toggleBtn.innerHTML = __( 'Rooms', 'realpress' );
							toggleBtn.classList.remove( 'has-value' );
						} else {
							roomInput.value = value;
							toggleBtn.innerHTML = value;
							toggleBtn.classList.add( 'has-value' );
						}
					} else {
						el.classList.remove( 'active' );
					}
				} );
			}
		} );
	} );

	//click tom select -> remove room active status
	const tomSelectNodes = document.querySelectorAll( 'select.realpress-tom-select, select.realpress-custom-select' );
	for ( let i = 0; i < tomSelectNodes.length; i++ ) {
		const tomSelectNode = tomSelectNodes[ i ];
		tomSelectNode.tomselect.on( 'focus', function() {
			roomField.classList.remove( 'active' );
		} );

		tomSelectNode.tomselect.on( 'change', function() {
			roomField.classList.remove( 'active' );
		} );
	}


	exactMatchNode.addEventListener( 'change', function( event ) {
		if ( exactMatchNode.checked ) {
			if ( roomInput.value !== 'any' ) {
				const value = roomInput.value.replace( '+', '' );
				roomInput.value = value;
				toggleBtn.innerHTML = value;
				toggleBtn.classList.add( 'has-value' );
			}
			[ ...selectItemNodes ].map( ( item ) => {
				if ( item.getAttribute( 'data-value' ) !== '0' ) {
					item.innerHTML = item.getAttribute( 'data-value' );
				}
			} );
		} else {
			if ( roomInput.value !== 'any' ) {
				const value = roomInput.value + '+';
				roomInput.value = value;
				toggleBtn.innerHTML = value;
				toggleBtn.classList.add( 'has-value' );
			}

			[ ...selectItemNodes ].map( ( item ) => {
				if ( item.getAttribute( 'data-value' ) !== '0' ) {
					item.innerHTML = item.getAttribute( 'data-value' ) + '+';
				}
			} );
		}
	} );

	toggleBtn.addEventListener( 'click', function( event ) {
		event.preventDefault();
		roomField.classList.toggle( 'active' );
	} );

	applyBtn.addEventListener( 'click', function( event ) {
		event.preventDefault();
		roomField.classList.remove( 'active' );
	} );
};

const getRoomValue = () => {
	if ( ! roomField ) {
		return '';
	}

	const roomInput = roomField.querySelector( 'input[name="room"]' );
	if ( roomInput && roomInput.value ) {
		if ( roomInput.value === 'any' ) {
			return '';
		}
		return roomInput.value;
	}

	return '';
};

const resetRoom = () => {
	if ( ! roomField ) {
		return;
	}

	roomInput = roomField.querySelector( 'input[name="room"]' );
	toggleBtn = roomField.querySelector( '.toggle' );
	roomInput.value = 'any';
	toggleBtn.innerHTML = __( 'Rooms', 'realpress' );

	[ ...selectItemNodes ].map( ( item ) => {
		if ( item.getAttribute( 'data-value' ) === '0' ) {
			item.classList.add( 'active' );
		} else {
			item.classList.remove( 'active' );
			item.innerHTML = item.getAttribute( 'data-value' );
			exactMatchNode.checked = true;
		}
	} );
};

export { getRoomValue, resetRoom };
export default realpressRoom;
