import { addQueryArgs } from '@wordpress/url';
const searchNode = document.querySelector( '.realpress-agent-search' );
const agentListPageUrl = REALPRESS_GLOBAL_OBJECT.agent_list_page_url || '';

const realpressAgentSearch = () => {
	if ( ! searchNode ) {
		return;
	}

	if ( ! agentListPageUrl ) {
		return;
	}

	const currentUrl = window.location.href;
	if ( currentUrl.includes( agentListPageUrl ) ) {
		return;
	}

	const fieldNodes = searchNode.querySelectorAll( 'input,select' );
	if ( ! fieldNodes ) {
		return;
	}

	const searchButton = searchNode.querySelector( 'button' );

	searchButton.addEventListener( 'click', function() {
		handleSearch();
	} );

	[ ...fieldNodes ].map( ( inputNode ) => {
		inputNode.addEventListener( 'keyup', function( event ) {
			if ( event.keyCode === 13 ) {
				handleSearch();
			}
		} );
	} );
};

const handleSearch = () => {
	const fieldNodes = searchNode.querySelectorAll( 'input,select' );
	if ( ! fieldNodes ) {
		return;
	}

	const query = { page: 1, orderby: 'display_name', order: 'asc' };
	for ( let i = 0; i < fieldNodes.length; i++ ) {
		const fieldNode = fieldNodes[ i ];
		const name = fieldNode.getAttribute( 'name' );

		if ( fieldNode.value === '' ) {
			if ( query.hasOwnProperty( name ) ) {
				delete query[ name ];
			}
		} else {
			query[ name ] = fieldNode.value;
		}
	}

	document.location.href = addQueryArgs( agentListPageUrl, query );
};

export default realpressAgentSearch;
