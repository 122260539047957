import { getCookie, setCookie } from '../../mixins/cookie';

const { __ } = wp.i18n;
const userId = REALPRESS_GLOBAL_OBJECT.user_id || '';
let popupTimeout;
const messagePopup = document.querySelector( '#realpress-snackbar' );

const realpressToggleCompare = () => {
	document.addEventListener( 'click', function( event ) {
		const compareNodes = document.querySelectorAll( '.realpress-property-compare' );
		for ( let i = 0; i < compareNodes.length; i++ ) {
			const compareNode = compareNodes[ i ];
			if ( compareNode.contains( event.target ) ) {
				handleToggleCompareProperty( compareNode );
			}
		}
	} );

	displayActiveCompare();
};

//Fix for cache page
const displayActiveCompare = () => {
	if ( userId ) {
		return;
	}

	const compare = getCompareCookie();

	if ( ! compare ) {
		return;
	}

	const compareBtnNodes = document.querySelectorAll( '.realpress-property-compare' );

	[ ...compareBtnNodes ].map( ( compareBtn ) => {
		const propertyId = parseInt( compareBtn.getAttribute( 'data-property-id' ) );

		if ( compare.includes( propertyId ) ) {
			compareBtn.classList.add( 'active' );
		} else {
			compareBtn.classList.remove( 'active' );
		}
	} );
};

const handleToggleCompareProperty = ( compareNode = null, propertyId = null ) => {
	const maximumCompare = 4;
	let propertyIds = getCompareCookie();
	if ( propertyIds === null || ! propertyIds ) {
		propertyIds = [];
	}

	if ( compareNode !== null ) {
		propertyId = compareNode.getAttribute( 'data-property-id' );
		propertyId = parseInt( propertyId );
	}

	let added = true;

	if ( propertyIds ) {
		//Remove compare property
		if ( propertyIds.includes( propertyId ) ) {
			added = false;
			propertyIds = propertyIds.filter( ( item ) => item !== propertyId );
		} else {
			if ( propertyIds.length >= maximumCompare ) {
				alert( sprintf( __( 'The maximum compare properties are %d', 'realpress' ), maximumCompare ) );
				return;
			}
			propertyIds.push( propertyId );
		}
	} else {
		propertyIds = [ propertyId ];
	}

	propertyId = compareNode.getAttribute( 'data-property-id' );

	//change to class active for list and grid template
	const similarNodes = document.querySelectorAll( `.realpress-property-compare[data-property-id="${ propertyId }"]` );
	[ ...similarNodes ].map( ( compareNode ) => {
		if ( added === true ) {
			compareNode.classList.add( 'active' );
		} else {
			compareNode.classList.remove( 'active' );
		}
	} );

	if ( added === true ) {
		messagePopup.innerHTML = __( 'Property Compare added', 'realpress' );
	} else {
		messagePopup.innerHTML = __( 'Property Compare removed', 'realpress' );
	}

	setCookie( 'realpress_compare_property', propertyIds.join( ',' ), 30 );
	displayMessagePopup();
};

const displayMessagePopup = () => {
	messagePopup.classList.add( 'active' );

	if ( popupTimeout ) {
		clearTimeout( popupTimeout );
	}

	popupTimeout = setTimeout( () => {
		messagePopup.classList.remove( 'active' );
		messagePopup.innerHTML = '';
	}, '3000' );
};

const getCompareCookie = () => {
	const propertyIds = getCookie( 'realpress_compare_property' );
	if ( propertyIds === null || ! propertyIds ) {
		return [];
	}

	return propertyIds.split( ',' ).map( Number );
};

export { getCompareCookie };

export default realpressToggleCompare;

