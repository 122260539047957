const { __ } = wp.i18n;
const yearBuiltFieldNode = document.querySelector( '.realpress-search-field.year' );
const realpressYearBuilt = () => {
	if ( ! yearBuiltFieldNode ) {
		return;
	}

	const fieldItemNodes = yearBuiltFieldNode.querySelectorAll( '.field-item' );
	[ ...fieldItemNodes ].map( ( fieldItemNode ) => {
		const displayItemNode = fieldItemNode.querySelector( '.display-item' );
		const inputNode = displayItemNode.querySelector( 'input[type="text"]' );
		const warningNode = displayItemNode.querySelector( 'span.warning' );

		inputNode.addEventListener( 'focus', function( event ) {
			fieldItemNode.classList.add( 'active' );
		} );

		document.addEventListener( 'click', function( event ) {
			if ( ! inputNode.contains( event.target ) ) {
				fieldItemNode.classList.remove( 'active' );
			}
		} );

		inputNode.addEventListener( 'change', function() {
			const value = inputNode.value;
			if ( isNaN( value ) ) {
				inputNode.value = '';
			} else {
				inputNode.value = value;
			}
			warningNode.innerHTML = '';
		} );

		inputNode.addEventListener( 'keyup', function() {
			let value = inputNode.value;
			if ( isNaN( value ) ) {
				warningNode.innerHTML = __( 'Invalid number', 'realpress' );
			} else {
				warningNode.innerHTML = '';
				if ( value ) {
					value = +value;
				}
				inputNode.value = value;
			}
		} );
	} );
};

const getMinYearValue = () => {
	if ( ! yearBuiltFieldNode ) {
		return '';
	}

	const minYearNode = yearBuiltFieldNode.querySelector( 'input[name="min-year"]' );
	if ( minYearNode && minYearNode.value ) {
		return minYearNode.value;
	}

	return '';
};

const getMaxYearValue = () => {
	if ( ! yearBuiltFieldNode ) {
		return '';
	}

	const maxYearNode = yearBuiltFieldNode.querySelector( 'input[name="max-year"]' );
	if ( maxYearNode && maxYearNode.value ) {
		return maxYearNode.value;
	}

	return '';
};

const resetYearBuilt = () => {
	if ( ! yearBuiltFieldNode ) {
		return '';
	}

	const minYearNode = yearBuiltFieldNode.querySelector( 'input[name="min-year"]' );
	const maxYearNode = yearBuiltFieldNode.querySelector( 'input[name="max-year"]' );

	minYearNode.value = '';
	maxYearNode.value = '';
};

export { getMinYearValue, getMaxYearValue, resetYearBuilt };
export default realpressYearBuilt;
