const { __ } = wp.i18n;
const verifiedField = document.querySelector( '.realpress-search-field.verified' );

const realpressVerified = () => {

};

const getVerifiedValue = () => {
	if ( ! verifiedField ) {
		return '';
	}

	if ( verifiedField.querySelector( 'input:checked' ) ) {
		return 'yes';
	}

	return '';
};

const resetVerified = () => {
	if ( ! verifiedField ) {
		return;
	}

	verifiedField.querySelector( 'input' ).checked = false;
};

export { getVerifiedValue, resetVerified };
export default realpressVerified;
