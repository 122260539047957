import Sortable from 'sortablejs';
import { on } from 'delegated-events';

const realpressMedia = () => {
	const { __ } = wp.i18n;
	const mediaElNodes = document.querySelectorAll( '.realpress-image-info' );
	if ( ! mediaElNodes ) {
		return;
	}

	on( 'click', '.realpress-image-info .realpress-image-add,.realpress-gallery-add', function( event ) {
		event.preventDefault();
		const mediaElNode = this.closest( '.realpress-image-info' );
		const multiple = mediaElNode.classList.contains( 'multiple' );

		const realpressUploader = wp.media( {
			title: __( 'Select Images', 'realpress' ), button: {
				text: __( 'Use these images', 'realpress' ),
			}, multiple, library: {
				type: 'image',
			},
		} );

		realpressUploader.on( 'select', function() {
			const selection = realpressUploader.state().get( 'selection' );
			if ( multiple === true ) {
				let attachments = selection.filter( function( item ) {
					return item.toJSON().type === 'image';
				} ).map( function( item ) {
					return item.toJSON();
				} );
				attachments = attachments.filter( ( item ) => {
					return validateFile( item, mediaElNode );
				} );
				let attachmentIds = attachments.map( function( item ) {
					return item.id;
				} );

				const number = mediaElNode.querySelector( 'input[type="hidden"]' ).getAttribute( 'data-number' );
				attachmentIds = attachmentIds.slice( 0, number );
				mediaElNode.querySelector( 'input' ).value = attachmentIds.join();

				//Gallery preview
				let galleryPreviewHtml = '';
				for ( let i = 0; i < attachmentIds.length; i++ ) {
					let src = '';
					let dataId = '';
					if ( !! attachmentIds[ i ] ) {
						dataId = attachmentIds[ i ];
						if ( !! attachments[ i ].sizes.thumbnail ) {
							src = attachments[ i ].sizes.thumbnail.url;
						} else {
							src = attachments[ i ].url;
						}
					}
					galleryPreviewHtml += `<div class="realpress-gallery-preview" data-id="${ dataId }">
						<div class="realpress-gallery-centered"><img src="${ src }" alt="#">
						<span class="realpress-gallery-remove dashicons dashicons dashicons-no-alt"></span>
						</div></div>`;
				}

				if ( !! galleryPreviewHtml ) {
					mediaElNode.querySelectorAll( '.realpress-gallery-preview' ).forEach( ( e ) => e.remove() );
					mediaElNode.querySelector( '.realpress-gallery-add' ).insertAdjacentHTML( 'beforebegin', galleryPreviewHtml );
				}
			} else {
				const attachment = selection.first().toJSON();
				if ( validateFile( attachment, mediaElNode ) ) {
					mediaElNode.querySelector( 'input[type=text]' ).value = attachment.url;
					mediaElNode.querySelector( 'input[type=hidden]' ).value = attachment.id;
					mediaElNode.querySelector( '.realpress-image-preview img' ).style.display = 'block';
					mediaElNode.querySelector( '.realpress-image-preview img' ).src = attachment.url;
				} else {
					mediaElNode.querySelector( 'input[type=text]' ).value = '';
					mediaElNode.querySelector( 'input[type=hidden]' ).value = '';
					mediaElNode.querySelector( '.realpress-image-preview img' ).style.display = 'none';
				}
			}
		} );
		realpressUploader.on( 'open', function() {
			const selection = realpressUploader.state().get( 'selection' );
			if ( multiple === true ) {
				let attachmentIds = mediaElNode.querySelector( 'input' ).value;

				if ( attachmentIds.length > 0 ) {
					attachmentIds = attachmentIds.split( ',' );
					attachmentIds.forEach( function( id ) {
						const attachment = wp.media.attachment( id );
						attachment.fetch();
						selection.add( attachment ? [ attachment ] : [] );
					} );
				}
			} else {
				const id = mediaElNode.querySelector( 'input[type=hidden]' ).value;
				const attachment = wp.media.attachment( id );
				attachment.fetch();
				selection.add( attachment ? [ attachment ] : [] );
			}
		} );

		realpressUploader.open();
	} );

	const sortElNode = document.querySelector( '.realpress-image-info.multiple .realpress-gallery-inner' );
	if ( sortElNode ) {
		Sortable.create( sortElNode, {
			handle: '.realpress-gallery-preview',
			draggable: '.realpress-gallery-preview',
			animation: 150,
			onEnd() {
				const mediaElNode = this.el.closest( '.realpress-image-info' );
				reorderIds( mediaElNode );
			},
		} );
	}

	on( 'click', '.realpress-image-info .realpress-gallery-remove', function( event ) {
		event.preventDefault();
		const imageInfo = this.closest( '.realpress-image-info' );
		this.closest( '.realpress-gallery-preview' ).remove();
		reorderIds( imageInfo );
	} );

	//Remove image
	for ( let i = 0; i < mediaElNodes.length; i++ ) {
		const mediaElNode = mediaElNodes[ i ];
		const removeButtonNode = mediaElNode.querySelector( 'button.realpress-image-remove' );
		if ( ! removeButtonNode ) {
			return;
		}
		removeButtonNode.addEventListener( 'click', function() {
			mediaElNode.querySelector( 'input[type=text]' ).value = '';
			mediaElNode.querySelector( 'input[type=hidden]' ).value = '';
			mediaElNode.querySelector( '.realpress-image-preview img' ).style.display = 'none';
		} );
	}
};

const reorderIds = ( mediaElNode ) => {
	const previewGalleries = mediaElNode.querySelectorAll( '.realpress-gallery-preview' );
	let dataIds = [];
	for ( let i = 0; i < previewGalleries.length; i++ ) {
		dataIds.push( previewGalleries[ i ].getAttribute( 'data-id' ) );
	}

	dataIds = dataIds.filter( function( el ) {
		return !! el;
	} );
	mediaElNode.querySelector( 'input' ).value = dataIds.join();
};

const validateFile = ( item, mediaElNode ) => {
	const maxFileSize = mediaElNode.getAttribute( 'data-max-file-size' );
	const itemFileSize = ( item.filesizeInBytes ) / 1024;

	if ( maxFileSize && maxFileSize < itemFileSize ) {
		return false;
	}
	const maxWidth = mediaElNode.getAttribute( 'data-max-width' );
	const itemWidth = item.width;

	if ( maxWidth && maxWidth < itemWidth ) {
		return false;
	}
	const maxHeight = mediaElNode.getAttribute( 'data-max-height' );
	const itemHeight = item.height;

	if ( maxHeight && maxHeight < itemHeight ) {
		return false;
	}

	return true;
};

export default realpressMedia;
